<template>
  <div>
    <component :is="isPage?'div':'BSidebar'" id="product-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
      :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
      <template v-slot:header>
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="productData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('supply-chain.product.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span class="text-capitalize">{{$t('supply-chain.product._')}}</span>
              <span class="m-0 h5">{{productData.names | name}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'product-view', params:{productId: productData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
      </template>
      
      <div v-if="productData" class="bg-white">
        <Carousel :files="productData.files" />
        <div class="shadow-top-sm flex justify-content-between align-items-center p-2">
          <div class="flex flex-column gap-1 ">
          <!-- taxable -->
            <div class="flex-even flex-size-6 flex align-items-center gap-2">
              <q-badge rounded :color="productData.isTaxable?'success':'secondary'"></q-badge>
              <span class="text-primary m-0 text-capitalize">{{$t('form.taxable')}}</span>
            </div>
            <!-- rewardable -->
            <div class="flex-even flex-size-6 flex align-items-center gap-2">
              <q-badge rounded :color="productData.isRewardable?'success':'secondary'"></q-badge>
              <span class="text-primary m-0 text-capitalize">{{$t('supply-chain.product.rewardable')}}</span>
            </div>
            <!-- is inventory -->
            <div class="flex-even flex-size-6 flex align-items-center gap-2">
              <q-badge rounded :color="productData.isInventory?'success':'secondary'"></q-badge>
              <span class="text-primary m-0 text-capitalize">{{$t('supply-chain.product.inventory._')}}</span>
            </div>
          </div>
          <!-- html -->
          <div v-if="decodeURIComponent(productData.html||'')">
            <q-btn outline @click="isHtmlDialogOpen=true" label="html" color="primary" />
            <q-dialog v-model="isHtmlDialogOpen" position="right" auto-close>
              <div v-html="decodeURIComponent(productData.html||'')" class="html-dialog bg-white overflow-auto p-1"/>
            </q-dialog>
          </div>
        </div>
        
        <q-separator inset />

        <div class="p-2 flex flex-column flex-sm-row flex-wrap gap-3">
          <!-- Name -->
          <div class="flex-even flex-size-4 flex gap-2">
            <fa-icon :icon="['fad','font-case']" fixed-width class="mt-50 text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block m-0 text-capitalize">{{$t('form.name')}}</label>
              {{productData.names | name}}
            </div>
          </div>
          <!-- slug -->
          <div class="flex-even flex-size-4 flex gap-2">
            <fa-icon :icon="['fad','hashtag']" fixed-width class="mt-50 text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block m-0 text-capitalize">{{$t('form.slug')}}</label>
              {{productData.slug }}
            </div>
          </div>
          <!-- Account -->
          <div class="flex-even flex-size-4 flex gap-2">
            <fa-icon :icon="['fad','sack-dollar']" fixed-width class="mt-50 text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block m-0 text-capitalize">{{$t('finance.account')}}</label>
              <q-chip v-if="productData.account" size="sm" :color="$global.account_color[productData.account.class]" text-color="white">
                {{productData.account.names | name}}
              </q-chip>
            </div>
          </div>
          <!-- Tags -->
          <div class="flex-even flex-size-4 flex gap-2">
            <fa-icon :icon="['fad','tags']" fixed-width class="mt-50 text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
              <span class="d-flex flex-wrap gap-1">
                <q-chip v-for="(tag, index) in productData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
                <q-btn flat dense @click.stop="updateTags"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
              </span>
            </div>
          </div>
          <!-- Description -->
          <div class="flex-even flex-size-6 d-flex gap-2">
            <fa-icon :icon="['fad','align-left']" fixed-width class="mt-50 text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block m-0 text-wrap text-capitalize">{{$t('form.description')}}</label>
              <span>{{productData.description}}</span>
            </div>
          </div>
        </div>
        
        <div class="p-2">
          <BTable class="variant-table position-relative text-center rounded-lg shadow mb-0" responsive show-empty hover primary-key="_id" sort-by="createdAt" sort-desc
            :items="productData.variants" :fields="[
              {key:'names',label:$t('form.variant')},
              {key:'quantity.in',label:$t('status.in._')},
              {key:'quantity.onhand',label:$t('status.onhand._')},
              {key:'quantity.out',label:$t('status.out._')}]">
            <template #empty>
              <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
              <h5>{{$t("table.noResult")}}</h5>
            </template>
            <!-- name -->
            <template #cell(names)="{ item }">
              <div @click.stop="variantViewRef.open(item._id)" class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-nowrap align-items-center gap-2">
                  <Avatar :url="item.files" size="md" />
                  <span class="h5 m-0 text-nowrap">{{ item.names | name }}</span>
                </div>
                <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1 mr-50"/>
              </div>
            </template>
          </BTable>
        </div>
      </div>
      <FieldDialog ref="fieldDialogRef"/>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </component>
    <VariantView ref="variantViewRef"/><!-- sidebar 覆盖 -->
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import useProduct from './useProduct'
import { BSidebar, BTable } from 'bootstrap-vue'
import { Dialog } from '@core/utils/other'
import { capitalize, fileUrl, price, name, status, volume, date } from '@core/utils/filter'
import FileGallery from '@core/components/file-gallery/FileGallery'
import Carousel from '@core/components/Carousel'
import VariantView from '../variant/VariantView'
import Avatar from '@core/components/Avatar'
import FieldDialog from '@core/components/FieldDialog'
export default {
  name: 'product-view',
  components: {
    Avatar, Carousel, FileGallery, FieldDialog,
    BSidebar, BTable,
    VariantView
  },
  props:{
    isPage: Boolean
  },
  filters:{
    capitalize, price, name, volume, date
  },
  setup(props) {
    const { route, router } = useRouter()
    const variantViewRef = ref(null)
    const {
      loading,
      productData,
      fetchProduct
    } = useProduct()

    // Page view
    if (props.isPage) {
      if (route.value.params.productId) {
        loading.value = true
        fetchProduct(route.value.params.productId, ()=>loading.value = false)
      } else {
        router.go(-1)
      }
    }

    // Sidebar
    const sidebarWidth = ref('600px')
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchProduct(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated) // parent 更新
            isSidebarActive.value = false
          }
        })
      })
    }

    // Tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:productData.value.tags, label:$t('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('product/updateTags', {productId: productData.value._id, tags:newTags} ).then(()=> fetchProduct(productData.value._id, ()=>{ // 更新本页面
        isUpdated = true
        loading.value = false
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    const isHtmlDialogOpen = ref(false)

    return {
      loading, fileUrl, status, volume, capitalize,
      variantViewRef,

      // Sidebar
      isSidebarActive,
      sidebarWidth,
      open,
      close,

      productData,
      fetchProduct,

      // Tags
      fieldDialogRef,
      updateTags,
      isHtmlDialogOpen
    }
  }
}
</script>

<style lang="scss">
#product-view {
  transition: all 300ms;
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }

  .variant-table {
    th {
      white-space: nowrap
    }
    td {
      padding: 0.5rem 1rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.chart {
  height: 500px;
}
.html-dialog {
  min-height:20rem;
  min-width: 93vw;
  @media (min-width:600px){
    min-width:40rem
  }
}
</style>