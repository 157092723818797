import { render, staticRenderFns } from "./ProductView.vue?vue&type=template&id=088b6f72&scoped=true&"
import script from "./ProductView.vue?vue&type=script&lang=js&"
export * from "./ProductView.vue?vue&type=script&lang=js&"
import style0 from "./ProductView.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ProductView.vue?vue&type=style&index=1&id=088b6f72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088b6f72",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QBadge,QDialog,QSeparator,QChip,QInnerLoading,QSpinnerGears});
