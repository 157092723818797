import { ref } from '@vue/composition-api'
import store from '@/store'
import $global from '@/globalVariables'
import productStoreModule from './productStoreModule'
if (!store.hasModule('product')) store.registerModule('product', productStoreModule)
import { swal } from '@core/utils/other'
import { i18n } from '@core/utils/filter'

export default function useProductList() {
  const loading = ref(false)
  const emptyProduct = ()=> ({
    slug:'',
    names: $global.locales.map(locale=>({locale, name:null})),
    account: null,
    isInventory: true,
    isActive: true,
    isTaxable: true,
    unit: 'pcs',
    variants: [],
    files:[], 
    tags:[]
  })

  const productData =  ref(emptyProduct())
  const resetProduct = ()=> productData.value = emptyProduct()

// Fetch a product
  const fetchProduct = (id, callback) => {
    if (!id) return
    store.dispatch('product/fetchProduct', id).then(response => {
      productData.value = response.product
      if(callback) callback(response)
    }).catch(() => productData.value = null )
  }

  const deleteProduct = async id =>{
    const v = await swal.fire({
      icon: 'warning',
      title: i18n('message.if_confirm_delete'),
      confirmButtonText: i18n('action.delete'),
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger rounded-lg text-capitalize',
        cancelButton: 'btn btn-outline-primary rounded-lg ml-1 text-capitalize',
      }
    })
    if(v.value) return store.dispatch('product/deleteProduct', id)
  }

  return {
    loading,
    productData,
    resetProduct,
    fetchProduct,
    deleteProduct
  }
}
